<template>
	<div id="download">

		<div class="pages-header">
			<el-image :src='require("../../assets/actbg@3x.png")' fit="cover">
			</el-image>
		</div>

		<div class="download-content">
			<div class="content-title">
				<p><i class="el-icon-s-home"></i> APP下载</p>
			</div>
			<div class="content-warp">
				<div class="content-left">
					<el-image :src='require("../../assets/download/downloadpic.png")' fit="cover">
					</el-image>
				</div>
				<div class="content-right">
					<div class="qrcode-warp">
						<p>下载APP</p>
						<img src="../../assets/download/schoolsafetyappdownload.png" width="200px" height="auto" />
					</div>
					<div class="qrcode-warp">
						<p>关注微信公众号</p>
						<img src="../../assets/qrcode.jpg" width="200px" height="auto" />
					</div>
					<!-- <div class="qrcode-warp">
						<p>微信小程序</p>
						<img src="../../assets/download/wxQRCode.svg" />
					</div> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "download",
		components: {},
		created() {
		},
		mounted() {
		},
		data() {
			return {
			};
		},
		methods: {
		}
	}
</script>

<style scoped lang="less">
	#download {
		font-size: 14px;
		font-family: Microsoft YaHei;
		/*font-weight: bold;*/
		color: #666666;
		line-height: 36px;

		.pages-header {
			width: 100%;
			height: 200px;

			.el-image {
				width: 100%;
				height: 200px;
			}
		}

		.download-content {
			width: 100%;

			.content-title {
				width: 100%;
				height: 87px;
				padding: 0 100px 0 100px;
				border-bottom: 1px solid #E5E5E5;
				display: flex;
				align-items: center;
				opacity: 0.8;
				font-weight: bold;

				p {
					margin: 0;
				}
			}

			.content-warp {
				/*height: 800px;*/
				/*background: url("../../assets/download/downloadbg.png") no-repeat;*/
				/*background-size: cover;*/
				/*background: rgb(191,219,249,0.1);*/
				padding: 5vw 10vw 5vw 10vw;
				display: flex;
				flex-wrap: wrap;
				.content-left {
					width: 50%;
					.el-image {
						width: 100%;
						border-radius: 20%;
						min-width: 500px;
					}
				}
				.content-right {
					width: 50%;
					display: flex;
					justify-content: space-between;
					padding: 5vw;
					.qrcode-warp {
						width: 172px;
						margin: 1vw 1vw 5vw 1vw;
						text-align: center;
						p {
							/*margin-bottom: 0;*/
							font-size: larger;
							background: white;
						}
					}
				}
			}
		}

		@media (max-width: 1100px) {
			.content-title {
				padding-left: 50px !important;
				height: 15vw !important;
			}
			.download-content {
				height: auto !important;
				.content-left {
					width: 100% !important;
					.el-image {
						min-width: 100% !important;
					}
				}
				.content-right {
					width: 100% !important;
					height: auto !important;
					flex-wrap: wrap;
					justify-content: space-around !important;
				}
			}
		}
	}


</style>
